.headerBanner-main-container {
    width: 100%;
    height: auto;
    margin: 0px;
    padding: 0px;
    position: relative;
    display: flex;
}

.headerBanner-image {
    visibility: hidden;
}

.headerBanner-active-image {
    width: 100%;
    visibility: visible;
}

.headerBanner-active-image img {
    height: auto;
    width: 100%;
    display: block;
    z-index: 9;
}

.headerBanner-slider-button-right {
    color: #d09d00;
    font-size: 45px;
    display: block;
    position: absolute;
    right: 25px;
    margin: -15px 0 0;
    top: 50%;
    opacity: 0;
    webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}


.headerBanner-slider-button-left {
    color: #d09d00;
    font-size: 45px;
    display: block;
    position: absolute;
    left: 25px;
    margin: -15px 0 0;
    opacity: 0;
    top: 50%;
    webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.headerBanner-main-container:hover > .headerBanner-slider-button-left {
    opacity: 1;
    left: 10%;
}

.headerBanner-main-container:hover > .headerBanner-slider-button-right {
    opacity: 1;
    right: 10%;
}

.headerBanner-slider-button-right:hover {
    color: black;
}

.headerBanner-slider-button-left:hover {
    color: black;
}

.headerBanner-slider-list {
    margin: 0;
    padding: 0;
    width: 100%;
    top: 95%;
    display: flex;
    position: absolute;
    justify-content: center;
    list-style: none;
    opacity: 1;
    webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.headerBanner-slider-list-button {
    margin-left: 10px;
    background-color: black;
    height: 10px;
    width: 10px;
    cursor: pointer;
    webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.headerBanner-slider-list-button:hover {
    background-color: #d09d00;
}

.headerBanner-slider-list-button-active {
    background-color: #d09d00;
}

/*FUNCTIONS*/

@media (max-width:756px) {
    .headerBanner-main-container {
        margin-top: 50px;
    }

    .headerBanner-slider-list {
        top: 90%;
    }
}