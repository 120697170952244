.appheader-main-container {
    margin: 0px;
    padding: 0px;
    width: 100%;
    text-align: center;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 30000;
    background-color: white;
    webkit-box-shadow: 0 4px 16px rgb(0 0 0 / 15%);
    box-shadow: 0 4px 16px rgb(0 0 0 / 15%);
}

.appheader-ul {
    list-style-type: none;
    justify-content: center;
    display: flex;
    padding: 0;
}

.appheader-ul li {
    display: inline;
    margin: 0px 14px;
    font-family: Raleway-Bold;
    font-size: 16px;
    color: black;
    font-weight: bold;
    text-decoration: none;
    list-style: none;
    font-weight: 800;
}

li a {
    text-decoration: none;
    color: #000000;
}

.appheader-ul li a:hover{
    color: #d09c00;
}


/*MOBILE---------------------------------------------------------------------------------------*/

.appheader-mobile {
    visibility: hidden;
    position: absolute;
    height: 100%;
}

.appheader-white-box {
    background-color: white;
    position: fixed;
    display: flex;
    width: 100%;
    height: 51px;
    z-index: -4;
    visibility: hidden;
}

.appheader-mobile-menu {
    display: flex;
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: rgb(255, 255, 255);
    z-index: -1;
}

.appheader-mobile-menu-text-button {
    background-color: black;
    padding: 8px;
    border-radius: 8px;
}

.appheader-mobile-menu-text {
    margin-left: 10px;
    font-family: Raleway-Bold;
    font-size: 15px;
    font-weight: 400;
    color: #000000;
    align-self: center;
}

.appheader-mobile-menu-close {
    display: flex;
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: rgb(255, 255, 255);
    visibility: hidden;
    z-index: 1;
}

.appheader-mobile-menu-box {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    visibility: hidden;
    width: 300px;
    height: 100vh;
    overflow: auto;
    z-index: 9999;
    border-right: 1px solid #ededed;
}

.appheader-mobile-menu-box-ul {
    margin-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
    list-style-type: none;
    padding: 0;
    border-top: 1px solid #ededed;
    padding-top: 10px;
    text-align: start;
    width: 94%;
}

.appheader-mobile-menu-box-ul li  {
    padding-left: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin: 0px 14px;
    font-family: Raleway;
    font-size: 14px;
    line-height: 26px;
    color: black;
    font-weight: bold;
    text-decoration: none;
    list-style: none;
    font-weight: 800;
}

.appheader-mobile-menu-box-ul > li a:hover {
    color: #d09c00;
}



/*Page size---------------------------------------------------------------------------------------*/

@media (max-width:756px) {
    .appheader-ul {
        display: none !important;
    }
    .appheader-mobile {
        visibility:visible;
    }
    .appheader-white-box {
        visibility:visible;
    }
}


/*Static css functions?-----------------------------------------------------------------------------------------------*/
.active {
    color: #d09c00;
}

.visible {
    visibility: visible;
}


