.subHeader-main-container {
    width: 100%;
    float: left;
    padding: 0px;
    background: #000;
    position: relative;
    z-index: 10;
}

/*LOGO*/
.subHeader-logo {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 20px;
    right: 0px;
    left: 0px;
}


/*PhoneIcon*/
.subHeader-phone-icon {
    float: left;
    text-align: center;
    padding: 0 25px;
    margin: 66px 0px;
    margin-right: 25px;
    margin-left: 25px;
    position: relative;
    border-right: 1px solid #4d4d4d;
}

.subHeader-phone-icon-1 {
    color: white;
    font-size: 26px;
    cursor: pointer;
}

.subHeader-phone-icon-1:hover {
    color: #d09d00;
}


/*EMAIL ICON*/
.subHeader-email-icon {
    float: left;
    width: auto;
    padding: 0px;
    margin: 65px 0;
    position: relative;
}

.subHeader-email-icon-1 {
    color: white;
    font-size: 28px;
    cursor: pointer;
}

.subHeader-email-icon-1:hover {
    color: #d09d00;
}


/*ACCOUNT ICON*/
.subHeader-account-icon {
    text-align: center;
    position: relative;
    float: right;
    padding: 0px;
    width: auto;
    margin: 65px 0;
}

.subHeader-account-icon-1 {
    color: white;
    font-size: 28px;
    cursor: pointer;
}

.subHeader-account-icon-1:hover {
    color: #d09d00;
}


/*CartIcon ICON*/
.subHeader-cart-icon {
    float: right;
    display: block;
    position: relative;
    margin: 65px 25px 64px;
    padding: 0 25px;
    border-left: 1px solid #4d4d4d;
}

.subHeader-cart-icon-1 {
    color: white;
    font-size: 28px;
    cursor: pointer;
}

.subHeader-cart-number-items {
    color: #ffffff;
    background: #d09d00;
    position: absolute;
    width: 8px;
    height: 20px;
    font-size: 12px;
    top: -16px;
    right: 28px;
    font-weight: 500;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: lowercase;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}

.subHeader-cart-icon-1:hover {
    color: #d09d00;
}



/*FUNCTIONS*/

@media (max-width:756px) {
    .subHeader-logo {
        position: relative;
    }

    .subHeader-logo a > img {
        max-width: 100%;
        max-height: 100%;
    }

    .subHeader-phone-icon {
        margin-left: 0px;
    }

    .subHeader-account-icon {
        margin-right: 0px;
    }
}


/*CONTAINER SPACE*/
@media (min-width: 1260px){
    .container-space {
        width: 1230px;
        max-width: 100%;
    }
}

.container-space {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}