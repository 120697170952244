/*FONTS*/

@font-face {
    font-family: Raleway-bold;
    src: url("../../assets/fonts/Raleway-ExtraBold.ttf");
}

@font-face {
    font-family: Raleway;
    src: url("../../assets/fonts/Raleway-Light.ttf");
}

@font-face {
    font-family: Raleway-black;
    src: url("../../assets/fonts/Raleway-Black.ttf");
}

@font-face {
    font-family: Righteous;
    src: url("../../assets/fonts/Righteous-Regular.ttf");
}

html, body { 
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.main-container {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}
