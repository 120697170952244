.storeItem-main-container {
    margin: 0px;
    position: relative;
}

/*MAIN IMAGE CONTAINER*/
.storeItem-image-container {
    position: relative;
    margin-bottom: 15px;
    overflow: hidden;
    padding: 0;
    border-bottom: 4px solid #cccccc;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

/*images*/
.storeItem-image {
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
}

.storeItem-image-main {
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    position: relative;
    opacity: 1;
}

.storeItem-image-sub {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
    position: absolute;
    opacity: 0;
}

.storeItem-image-container:hover .storeItem-image-main{
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
    opacity: 0;
    position: absolute;
}

.storeItem-image-container:hover .storeItem-image-sub{
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    position: relative;
    opacity: 1;
}
  
/*flags*/
.storeItem-image-flag {
    margin: 0px;
    padding: 0px;
}

.storeItem-image-flag li {
    display: block;
    position: absolute;
    padding: 4px 10px;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: #fff;
    background: #ff001e;
    z-index: 2;
    font-family: 'Raleway', sans-serif;
    text-align: center;
    margin: 0;
    left: 0px;
    bottom: 0px;
}

.storeItem-image-container:hover .storeItem-image-flag-discount {
    filter: alpha(opacity=100);
    -webkit-transition: all 300ms ease 0s;
    -moz-transition: all 300ms ease 0s;
    -o-transition: all 300ms ease 0s;
    transition: all 300ms ease 0s;
    opacity: 0;
}

.storeItem-image-flag-discount-value {
    vertical-align: top;
    display: inline;
    padding: 5px 6px;
    letter-spacing: 1.2px;
    font-size: 10px;
    font-weight: bold;
    line-height: initial;
    font-weight: 600;
    background: #fff000;
    color: #000;
    position: absolute;
    right: 0;
    bottom: 0px;
    font-family: 'Raleway', sans-serif;
}


/*MAIN DESCRIPTION CONTAINER*/
.storeItem-description-product-name {
    text-transform: uppercase;
    display: block;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    color: #5a5a5a;
    font-weight: 800;
    font-family: 'Raleway', sans-serif;
}

.storeItem-main-container:hover .storeItem-description-product-name {
    color: #d09d00;
}

.storeItem-description-product-price {
    color: #000000;
    font-weight: 800;
    margin-bottom: 5px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
}

.storeItem-description-product-price-main {
    color: #878787;
    text-decoration: line-through;
    font-size: 15px;
    padding: 0 3px;
    display: inline-block;
    vertical-align: bottom;
    font-weight: 400;
}

.storeItem-description-product-price-discountPrice {
    color: #000000;
    font-weight: 800;
    margin-bottom: 5px;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    vertical-align: top;
}

@media (min-width: 1260px) {
    .container-space{
        padding-right: 0px;
    }
}

@media (max-width: 1278px) {
    .container-space{
        padding-right: 0px;
    }
}