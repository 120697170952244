.bottomFooter-main-container{
    position: relative;
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
}

.bottomFooter-newsletter-container {
    position: relative;
    width: 100%;
    margin: 0px;
    padding: 0px;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: black;
}

.bottomFooter-newsletter-text {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-left: 40px;
    overflow: hidden;
}

.bottomFooter-newsletter-text-title {
    font-family: "Raleway-Bold";
    color: #d09d00;
    font-size: 30px;
    font-weight: 700;
    color: #d09d00;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.bottomFooter-newsletter-text-description {
    font-size: 13px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.6px;
    color: #ababab;
    display: block;
    text-transform: capitalize;
    font-family: "Raleway";
}


.bottomFooter-newsletter-subcribe {
    position: relative;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 40px;
    margin-left: 40px;
}

.bottomFooter-newsletter-subcribe-input{
    border: 0;
    border-bottom: 1px solid #333333;
    padding: 10px 10px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.4px;
    background: #000000;
    color: #999999;
    width: 100%;
    min-width: 150px;
    max-width: 400px;
}

.bottomFooter-newsletter-subcribe-input:focus {
    background-color: #ffffff;
    outline: none;
}

.bottomFooter-newsletter-subcribe-button {
    cursor: pointer;
    float: right !important;
    margin-left: 0px;
    background: #d09d00;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 10px 22px;
    font-family: "Raleway";
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.bottomFooter-newsletter-subcribe-button:hover {
    background: #ffffff;
    color: #d09d00;
}


@media (max-width: 1158px) {
    .bottomFooter-newsletter-subcribe {
        margin-top: 20px;
        margin-right: 20px;
    }

    .bottomFooter-newsletter-container {
        flex-direction: column;
    }
}




/*FOOTER*/
.bottomFooter-footer-main-cointaner {
    position: relative;
    width: 100%;
    padding: 0px;
    padding-top: 50px;
    display: flex;
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
}

.bottomFooter-footer-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content:space-around;
    width: 100%;
    padding: 0px;
}

/*STORE INFO---------------------------------------------------------------------*/
.bottomFooter-footer-store-info {
    position: relative;
}

.bottomFooter-footer-store-info a {
    text-decoration: none;
}

.bottomFooter-footer-store-info-title {
    color: #000000;
    font: 800 20px/26px 'Raleway-Bold', sans-serif;
    text-transform: uppercase !important;
    margin: 0 0 20px 0;

}

.bottomFooter-footer-store-info-title:hover {
    color: #d09d00;
}

.bottomFooter-footer-store-info ul {
    position: relative;
    list-style-type: none;
    padding-left: 0;
}

.bottomFooter-footer-store-info li {
    position: relative;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0.8px;
    font-weight: 500;
    font-family: 'Raleway';
    font-weight: bold;
}

.bottomFooter-footer-store-info ul > li:nth-child(2) {
    margin-top: 10px;
}

.bottomFooter-footer-store-info ul > li:last-child {
    margin-top: 14px;
}

.bottomFooter-footer-store-info-icon {
    color: #d09d00;
}

/*your account*/
.bottomFooter-footer-account {
    position: relative;
}

.bottomFooter-footer-account a {
    text-decoration: none;
}

.bottomFooter-footer-account-title {
    color: #000000;
    font: 800 20px/26px 'Raleway-Bold', sans-serif;
    text-transform: uppercase !important;
    margin: 0 0 20px 0;
}

.bottomFooter-footer-account-title:hover {
    color: #d09d00;
}

.bottomFooter-footer-account ul {
    display: inherit;
    list-style-type: none;
    padding-left: 0;
}

.bottomFooter-footer-account li {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0.8px;
    font-weight: 500;
    font-family: 'Raleway';
    font-weight: bold;
    margin-top: 10px;
}

.bottomFooter-footer-account-icon {
    color: #d09d00;
}

/*MORE*/
.bottomFooter-footer-more {
    position: relative;
}

.bottomFooter-footer-more a {
    text-decoration: none;
}

.bottomFooter-footer-more-title {
    color: #000000;
    font: 800 20px/26px 'Raleway-Bold', sans-serif;
    text-transform: uppercase !important;
    margin: 0 0 20px 0;
}

.bottomFooter-footer-more-title:hover {
    color: #d09d00;
}

.bottomFooter-footer-more ul {
    display: inherit;
    list-style-type: none;
    padding-left: 0;
}

.bottomFooter-footer-more li {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0.8px;
    font-weight: 500;
    font-family: 'Raleway';
    font-weight: bold;
    margin-top: 10px;
}

.bottomFooter-footer-more-icon {
    color: #d09d00;
}


@media (max-width: 665px) {
    .bottomFooter-footer-container {
        flex-direction: column;
        justify-content: center;
        margin-left: 50%;
        margin-right: 50%;
    }

    .bottomFooter-footer-sub-containers {
        display: flex;
        justify-content: center;
        flex-direction: column;
        
    }

    .bottomFooter-footer-store-info li {
        display: flex;
        flex-direction: row;
    }

    .bottomFooter-footer-sub-containers ul {
        display: inline-block;
    }
}

