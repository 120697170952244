.home-main-container {
    position: relative;
}

/*WELCOME*/
.home-welcome-container {
    position: relative;
    padding: 100px 0 50px;
    letter-spacing: 0.6px;
    text-align: center;
    clear: both;
    display: flex;
    flex-direction: column;
}

.home-welcome-title {
    display: inline-block;
    width: auto;
    margin: 0 auto 25px;
    padding: 0 0px 0px;
    color: black;
    position: relative;
    text-align: center;
    font: 400 70px/80px 'Righteous', cursive;
    letter-spacing: 0px;
}

.home-welcome-description {

    font-size: 14px;
    font-weight: 500;
    color: #818181;
    letter-spacing: 0.8px;
    font-family: "Raleway";
    margin-left: 30px;
    margin-right: 30px;
}


/*PRODUCTS*/
.home-products-container {
    text-align: center;
    clear: both;
    padding: 50px 0 30px;
    position: relative;
}

.home-products-tabs {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 90px;
    background: url("../../assets//images/divider.png") no-repeat scroll center 50px;
}

.home-products-tabs ul {
    padding: 0px;
    overflow: hidden;
}

.home-products-tabs ul > li {
    white-space: nowrap;
    cursor: pointer;
    color: #565656;
    display: inline;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    font-family: "Raleway";
    line-height: 22px;
    overflow: hidden;
    padding: 10px 30px;
    position: relative;
    letter-spacing: 1.2px;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s
}

.home-products-tabs ul > li:hover {
    color: #d09d00;
}


/*PRODUTOS*/
.home-products-main-container {
    list-style: none;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.home-products-main-container li {
    padding: 10px 15px 15px;
}


.home-products-view-more-container {
    position: relative;
    justify-content: center;
    display: flex;
    margin-top: 35px;
    width: 100%;
    padding: 0;
}

.home-products-view-more {
    clear: both;
    display: inline-block;
    text-align: center;
    font-size: 13px;
    padding: 10px 30px;
    background-color: #d09d00;
    border: 1px solid #d09d00;
    font-family: "Raleway-Bold";
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #ffffff;
    text-transform: uppercase;
    -webkit-transition: all 500ms ease 0s;
    -moz-transition: all 500ms ease 0s;
    -o-transition: all 500ms ease 0s;
    transition: all 500ms ease 0s;
}

.home-products-view-more:hover {
    border: 1px solid black;
    background-color: black;
}


.home-service-main-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 0px;
    padding-bottom: 25px;
    margin-top: 80px;
    justify-content: center;
}

.home-service-icon {
    color: black;
    font-size: 20px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 5px 30px;
}

.home-service-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    color: #000000;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    font-family: "Raleway-Bold";
}

.home-service-icon:hover {
    color: #d09d00;
}

.home-service-icon:hover .home-service-text{
    color: #d09d00;
}

.home-service-shipping {
    border-right: 1px solid #e5e5e5;
}

.home-service-money-back {
    border-right: 1px solid #e5e5e5;
}





/*Funtions*/
@media (max-width: 992px){
    .home-welcome-title {
        font-size: 30px;
        line-height: initial;
        margin-bottom: 15px;
    }

    .home-welcome-container {
        width: 720px;
        max-width: 100%;
    }

    .home-products-container {
        width: 720px;
        max-width: 100%;
    }
}


@media (max-width: 538px){
    .home-products-tabs ul > li {
        display: block;
    }

    .home-products-tabs {
        background-position: center 150px;
    }
}

@media (max-width: 814px){
    .home-service-main-container{
        flex-direction: column;
    }

    .home-service-icon{
        margin-top: 15px;
    }
}
